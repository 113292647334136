import List from "./List";
import ListHeader from "./ListHeader";
import ListItem from "./ListItem";
import ListMainText from "./ListMainText";

List.Header = ListHeader;
List.Item = ListItem;
List.MainText = ListMainText;

export default List;
