import { axios } from ".";

const documentAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/documents`,
});

async function createDocumentsApi(data) {
  const response = await documentAxios({
    method: "post",
    url: "/create",
    data,
  });
  return response.data;
}

async function updateDocumentsApi(data) {
  const response = await documentAxios({ method: "put", url: "/update", data });
  return response.data;
}

async function getDocumentsApi(guid) {
  const response = await documentAxios({ method: "get", url: `/get/${guid}` });
  return response.data;
}

async function duplicateDocumentsApi(data) {
  const response = await documentAxios({
    method: "post",
    url: "/duplicate",
    data,
  });
  return response.data;
}

async function deleteDocumentsApi(guid) {
  const response = await documentAxios({ method: "delete", url: `/${guid}` });
  return response.data;
}

export {
  documentAxios,
  createDocumentsApi,
  updateDocumentsApi,
  getDocumentsApi,
  duplicateDocumentsApi,
  deleteDocumentsApi,
};
