const state = {
  systemUser: {
    systemUserMe: {
      _id: "",
      email: "",
      name: "",
      role: "",
    },
    systemUserSearchResults: [],
    chosenSystemUser: {
      _id: "",
      email: "",
      name: "",
      role: "",
    },
  },
};

export { state as default };
