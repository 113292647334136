import { axios } from ".";

const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/auth`,
});

async function loginApi(data) {
  const response = await authAxios({ method: "post", url: "/login", data });
  return response.data;
}

async function logoutApi(data) {
  const response = await authAxios({ method: "post", url: "/logout", data });
  return response.data;
}

async function refreshTokenApi(data) {
  const response = await authAxios({
    method: "post",
    url: "/token/refresh",
    data,
  });
  return response.data;
}

export { authAxios, loginApi, logoutApi, refreshTokenApi };
