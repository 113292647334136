import { thunk } from "easy-peasy";
import { getAllErpsApi } from "../../api/erp";

const thunks = {
  getAllErpsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      actions.setErpsAction(await getAllErpsApi());
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
