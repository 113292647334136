import { useStoreActions, useStoreState } from "easy-peasy";
import { links } from "../../../utilities";
import TopNavbar from "../../presentational/TopNavbar";
import Login from "../Login";
import Page from ".";
import { useEffect } from "react";

const navLinks = [
  {
    href: links.HOME,
    label: "Home",
  },
  {
    href: links.ADD_NEW_DOCUMENTS,
    label: "Add New Documents",
  },
];

function usePage() {
  const { systemUserMe } = useStoreState((state) => state.systemUser);

  const { loadSystemUserMeThunk, setSystemUserMeAction, setLoadingAction } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    async function loadSystemUserMe() {
      setLoadingAction(true);
      await loadSystemUserMeThunk();
      setLoadingAction(false);
    }

    loadSystemUserMe();
  }, [setLoadingAction, loadSystemUserMeThunk, setSystemUserMeAction]);

  return {
    me: systemUserMe,
    pageLinks: systemUserMe.kioskOwner
      ? [
          ...navLinks,
          {
            href: links.USERS,
            label: "Users",
          },
        ]
      : [
          ...navLinks,
          {
            href: links.PROFILE,
            label: "Profile",
          },
        ],
  };
}

function PageProtected({ children, ...props }) {
  const { me, pageLinks } = usePage();
  return (
    <Page {...props}>
      {me._id ? (
        <>
          <TopNavbar navLinks={pageLinks} />
          {children}
        </>
      ) : (
        <Login />
      )}
    </Page>
  );
}

export default PageProtected;
