import { axios } from ".";

const fileAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/file`,
});

async function upsertFileApi(data) {
  const response = await fileAxios({ method: "put", url: "/upsert", data });
  return response.data;
}

async function searchFilesApi({ name, settingId }) {
  const response = await fileAxios({
    method: "get",
    url: `/search?name=${name}&settingId=${settingId}`,
  });
  return response.data;
}

async function getFileApi(id) {
  const response = await fileAxios({ method: "get", url: `/get/${id}` });
  return response.data;
}

export { fileAxios, upsertFileApi, searchFilesApi, getFileApi };
