import { useStoreActions, useStoreState } from "easy-peasy";
import SearchResultsView from "../presentational/SearchResults";
import Document from "../presentational/Document";
import { styled } from "twin.macro";
import Page from "../containers/Page";
import Center from "../presentational/Center";
import SearchField from "../functional/SearchField";
import List from "../functional/List";
import { useEffect, useState } from "react";
import Form from "../functional/Form";
import { useNavigate } from "react-router-dom";
import { links } from "../../utilities";

const SearchResultsMessage = styled(SearchResultsView.Message)`
  margin-top: 12%;
`;

function usePage() {
  const { systemUserSearchResults, chosenSystemUser, systemUserMe } =
    useStoreState((state) => state.systemUser);

  const {
    searchSystemUsersThunk,
    setLoadingAction,
    setChosenSystemUserAction,
  } = useStoreActions((actions) => actions);

  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    if (systemUserMe.kioskOwner) searchSystemUsersThunk("");
    else !systemUserMe.kioskOwner && navigate(links.PROFILE);
  }, [navigate, searchSystemUsersThunk, systemUserMe.kioskOwner]);

  return {
    hasSearched,
    searchResults: systemUserSearchResults.map((user) => ({
      ...user,
      onClick: () => setChosenSystemUserAction(user),
    })),
    searchInput,
    onChangeSearchInput: (evt) => setSearchInput(evt.target.value),
    onSearchSystemUsers: async () => {
      setLoadingAction(true);
      await searchSystemUsersThunk(searchInput);
      setHasSearched(true);
      setLoadingAction(false);
    },
    cannotFindUser: hasSearched && systemUserSearchResults.length === 0,
    chosenSystemUser,
  };
}

function UsersPage() {
  const {
    searchResults,
    searchInput,
    onChangeSearchInput,
    onSearchSystemUsers,
    cannotFindUser,
    chosenSystemUser,
  } = usePage();

  return (
    <Page.Protected>
      <Document>
        {chosenSystemUser._id ? (
          <Form.Profile />
        ) : (
          <>
            <Center>
              <SearchField
                value={searchInput}
                onChange={onChangeSearchInput}
                onSubmit={onSearchSystemUsers}
                placeholder="Enter user name"
              />
            </Center>

            <br />

            {searchResults.length > 0 && (
              <>
                <List.Header>Results</List.Header>
                <List list={searchResults} />
              </>
            )}

            {cannotFindUser && (
              <Center>
                <SearchResultsMessage>
                  No users found with that name
                </SearchResultsMessage>
              </Center>
            )}
          </>
        )}
      </Document>
    </Page.Protected>
  );
}

export default UsersPage;
