import { thunk } from "easy-peasy";
import { upsertFileApi, searchFilesApi, getFileApi } from "../../api/file";
import { defaultState } from "./state";

const thunks = {
  createFileThunk: thunk(async (actions, { successMsg, ...fileObj }) => {
    actions.setLoadingAction(true);

    try {
      actions.setFileDocumentAction(await upsertFileApi(fileObj));
      actions.setShowAddFileModalAction(false);
      actions.setAlertThunk({
        type: "success",
        message: successMsg,
      });
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  searchFilesThunk: thunk(async (actions, queryJson) => {
    actions.setLoadingAction(true);

    try {
      actions.setFileSearchResultsAction(await searchFilesApi(queryJson));
      actions.setFileDocumentAction(defaultState.document);
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  getFileThunk: thunk(async (actions, id) => {
    actions.setLoadingAction(true);

    try {
      actions.setFileDocumentAction(await getFileApi(id));
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
