import { thunk } from "easy-peasy";
import { searchUsersApi, resetUserPasswordApi } from "../../api/user";

const thunks = {
  searchUsersThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      actions.setUserSearchResultsAction(
        await searchUsersApi(helpers.getStoreState().user.searchInput)
      );
      actions.resetUserDocumentAction();
      actions.resetClientDocumentAction();
    } catch (e) {
      actions.setUserSearchResultsAction([]);
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  resetUserPasswordThunk: thunk(async (actions, newPasswordInput) => {
    try {
      actions.setUserDocumentAction(
        await resetUserPasswordApi(newPasswordInput)
      );
      actions.setAlertThunk({
        type: "success",
        message: "Password has been reset successfully.",
      });
      return true;
    } catch (e) {
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }
  }),
};

export default thunks;
