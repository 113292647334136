import { refreshTokenApi } from "../api/auth";

const tokenKey = "token";

function formatAuthHeader(token, type = "Bearer") {
  return `${type} ${token}`;
}
function getAuthToken() {
  return localStorage.getItem(tokenKey);
}

function setAuthToken(token) {
  localStorage.setItem(tokenKey, token);
}

function removeAuthToken() {
  localStorage.removeItem(tokenKey);
}

function axiosInterceptor(axios, store) {
  axios.interceptors.request.use((config) => {
    config.headers.Authorization = formatAuthHeader(getAuthToken());
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.config.url.includes("token/refresh")) {
        store.getActions().resetSystemUserMeAction();
        removeAuthToken();
      } else if (error.response.status === 401) {
        // refresh token
        const newAccessToken = await refreshTokenApi();
        setAuthToken(newAccessToken);
        error.config.headers.Authorization = formatAuthHeader(newAccessToken);
        return Promise.resolve(await axios.request(error.config));
      }

      return Promise.reject(error);
    }
  );
}

export {
  getAuthToken,
  setAuthToken,
  removeAuthToken,
  formatAuthHeader,
  axiosInterceptor,
};
