import Button from "../../presentational/Button";
import { useStoreActions } from "easy-peasy";

function useOnClick() {
  const { logoutThunk } = useStoreActions((actions) => actions);
  return () => logoutThunk();
}

function ButtonLogout({ children = "Sign Out", ...props }) {
  const onClick = useOnClick();
  return (
    <Button onClick={onClick} variant="light" {...props}>
      {children}
    </Button>
  );
}

export default ButtonLogout;
