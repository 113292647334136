import { axios } from ".";

const settingAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/setting`,
});

async function upsertSettingApi(data) {
  const response = await settingAxios({ method: "put", url: "/upsert", data });
  return response.data;
}

async function searchSettingsApi({ name, clientGuid }) {
  const response = await settingAxios({
    method: "get",
    url: `/search?name=${name}&clientGuid=${clientGuid}`,
  });
  return response.data;
}

async function getSettingApi(id) {
  const response = await settingAxios({ method: "get", url: `/get/${id}` });
  return response.data;
}

export { settingAxios, upsertSettingApi, searchSettingsApi, getSettingApi };
