import ListItem from "./ListItem";
import ListMainText from "./ListMainText";

function List({ list }) {
  return list.map(({ _id, name, onClick }) => (
    <ListItem key={_id} onClick={onClick}>
      <ListMainText>{name}</ListMainText>
    </ListItem>
  ));
}

export default List;
