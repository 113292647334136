import { thunk } from "easy-peasy";
import {
  createDocumentsApi,
  deleteDocumentsApi,
  duplicateDocumentsApi,
  getDocumentsApi,
  updateDocumentsApi,
} from "../../api/document";
import { getFileApi } from "../../api/file";
import { getSettingApi } from "../../api/setting";

const thunks = {
  toggleDocumentThunk: thunk(async (actions, docIndex, helpers) => {
    if (helpers.getStoreState().activeDocuments.includes(docIndex))
      actions.collapseDocumentAction(docIndex);
    else actions.expandDocumentAction(docIndex);
  }),
  getDocumentsThunk: thunk(async (actions, guid) => {
    actions.setLoadingAction(true);

    try {
      const documents = await getDocumentsApi(guid);
      actions.setUserDocumentAction(documents.user);
      actions.setClientDocumentAction(documents.client);
      actions.resetSettingStateAction();
      actions.resetFileStateAction();
      actions.setActiveDocumentsAction(["0"]);
    } catch (e) {
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  updateDocumentsThunk: thunk(
    async (actions, { userInput, clientInput, settingInput, fileInput }) => {
      actions.setLoadingAction(true);

      try {
        const updatedDocuments = await updateDocumentsApi({
          user: userInput,
          client: clientInput,
          setting: settingInput,
          file: fileInput,
        });
        updatedDocuments.user._id &&
          actions.setAlertThunk({
            type: "success",
            message: "User updated",
          });
        updatedDocuments.client._id &&
          actions.setAlertThunk({
            type: "success",
            message: "Client updated",
          });
        updatedDocuments.setting._id &&
          actions.setAlertThunk({
            type: "success",
            message: "Setting updated",
          });
        updatedDocuments.file._id &&
          actions.setAlertThunk({
            type: "success",
            message: "File updated",
          });

        const [userClient, setting, file] = await Promise.all([
          getDocumentsApi(updatedDocuments.client.guid),
          updatedDocuments.setting._id
            ? getSettingApi(updatedDocuments.setting._id)
            : Promise.resolve(settingInput),
          updatedDocuments.file._id
            ? getFileApi(updatedDocuments.file._id)
            : Promise.resolve(fileInput),
        ]);
        actions.setUserDocumentAction(userClient.user);
        actions.setClientDocumentAction(userClient.client);
        actions.setSettingDocumentAction(setting);
        actions.setFileDocumentAction(file);
      } catch (e) {
        actions.setAlertThunk({
          type: "error",
          message: e.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  createDocumentsThunk: thunk(
    async (actions, { navigate, user, client }, helpers) => {
      actions.setLoadingAction(true);

      try {
        const documents = await createDocumentsApi({
          user,
          client,
        });
        actions.setUserDocumentAction(documents.user);
        actions.setClientDocumentAction(documents.client);
        navigate("/");
      } catch (e) {
        actions.setAlertThunk({
          type: "error",
          message: e.response.data,
        });
      }

      actions.setLoadingAction(false);
    }
  ),
  duplicateDocumentsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const documents = await duplicateDocumentsApi({
        guidSource: helpers.getStoreState().user.document.clientGuid,
        name: helpers.getStoreState().documentDuplicationInput.name,
        email: helpers.getStoreState().documentDuplicationInput.email,
        password: helpers.getState().documentDuplicationInput.password,
        cpassword: helpers.getState().documentDuplicationInput.cpassword,
      });
      actions.setUserDocumentAction(documents.user);
      actions.setClientDocumentAction(documents.client);
      actions.resetSettingStateAction();
      actions.resetFileStateAction();
      actions.resetDocumentDuplicationInputAction();
      actions.setShowDuplicateDocumentsModalAction(false);
      actions.setActiveDocumentsAction(["0"]);
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  deleteDocumentsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      await deleteDocumentsApi(
        helpers.getStoreState().user.document.clientGuid
      );
      actions.resetFileStateAction();
      actions.resetSettingStateAction();
      actions.setShowDeleteDocumentsModalAction(false);
      await actions.searchUsersThunk();
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
