import Page from "../containers/Page";
import Center from "../presentational/Center";
import tw, { styled } from "twin.macro";
import Document from "../presentational/Document";
import Button from "../presentational/Button";
import { useStoreActions } from "easy-peasy";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

const SForm = styled(Form)`
  max-width: 550px;
  background-color: #f8f9fa !important;
  ${tw`w-full p-8`}
`;

function usePage() {
  const { setLoadingAction, resetSystemUserPasswordThunk } = useStoreActions(
    (actions) => actions
  );

  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const navigate = useNavigate();

  return {
    password,
    onChangePasswordInput: (evt) => setPassword(evt.target.value),
    cpassword,
    onChangeConfirmPasswordInput: (evt) => setCPassword(evt.target.value),
    onResetPassword: async () => {
      setLoadingAction(true);
      const params = new URLSearchParams(window.location.search);
      const isSuccessful = await resetSystemUserPasswordThunk({
        _id: params.get("id"),
        password,
        cpassword,
      });
      isSuccessful && navigate(-1);
      setLoadingAction(false);
    },
    isMatchingPassword: password.trim() === cpassword.trim(),
  };
}

function PasswordResetSystemUserPage() {
  const {
    password,
    onChangePasswordInput,
    cpassword,
    onChangeConfirmPasswordInput,
    onResetPassword,
    isMatchingPassword,
  } = usePage();
  return (
    <Page.Protected>
      <Center.Screen>
        <SForm onSubmit={onResetPassword}>
          <Document.Header>
            <Center>Reset User's Password</Center>
          </Document.Header>

          <br />

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control value={password} onChange={onChangePasswordInput} />
          </Form.Group>

          <br />

          <Form.Group>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              value={cpassword}
              onChange={onChangeConfirmPasswordInput}
            />
          </Form.Group>

          <br />

          <Button disabled={!isMatchingPassword} type="submit" variant="danger">
            Reset Password
          </Button>
        </SForm>
      </Center.Screen>
    </Page.Protected>
  );
}

export default PasswordResetSystemUserPage;
