import { action } from "easy-peasy";
import defaultState from "./state";

const actions = {
  setSystemUserMeAction: action((state, user) => {
    state.systemUser.systemUserMe = user;
  }),
  resetSystemUserMeAction: action((state) => {
    state.systemUser.systemUserMe = defaultState.systemUser.systemUserMe;
  }),
  setSystemUserSearchResultsAction: action((state, list) => {
    state.systemUser.systemUserSearchResults = list;
  }),
  setChosenSystemUserAction: action((state, user) => {
    state.systemUser.chosenSystemUser = user;
  }),
  resetChosenSystemUserAction: action((state) => {
    state.systemUser.chosenSystemUser =
      defaultState.systemUser.chosenSystemUser;
  }),
};

export default actions;
