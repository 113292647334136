import { axios } from ".";

const clientAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/client`,
});

async function confirmAcumaticaApi(clientGuid) {
  const response = await clientAxios({
    method: "put",
    url: `/${clientGuid}/acumatica/verify`,
  });
  return response.data;
}

export { clientAxios, confirmAcumaticaApi };
