import { thunk } from "easy-peasy";
import {
  upsertSettingApi,
  searchSettingsApi,
  getSettingApi,
} from "../../api/setting";

const thunks = {
  createSettingThunk: thunk(async (actions, { successMsg, ...setting }) => {
    actions.setLoadingAction(true);

    try {
      actions.setSettingDocumentAction(await upsertSettingApi(setting));
      actions.setShowAddSettingModalAction(false);
      actions.resetFileStateAction();
      actions.setAlertThunk({
        type: "success",
        message: successMsg,
      });
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  searchSettingsThunk: thunk(async (actions, queryJson) => {
    actions.setLoadingAction(true);

    try {
      actions.setSettingSearchResultsAction(await searchSettingsApi(queryJson));
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  getSettingThunk: thunk(async (actions, id) => {
    actions.setLoadingAction(true);

    try {
      actions.setSettingDocumentAction(await getSettingApi(id));
      actions.resetFileStateAction();
    } catch (e) {
      console.log(e);
      actions.setAlertThunk({
        type: "error",
        message: e.response.data.message,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
