import { axios } from ".";

const systemUserAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/system-users`,
});

async function getSystemUserMeApi() {
  const response = await systemUserAxios({ method: "get", url: "/me" });
  return response.data;
}

async function searchSystemUsersApi(searchInput) {
  const response = await systemUserAxios({
    method: "get",
    url: `?name=${searchInput}`,
  });
  return response.data;
}

async function updateSystemUserApi(data) {
  const response = await systemUserAxios({
    method: "put",
    url: `/${data._id}`,
    data,
  });
  return response.data;
}

async function resetSystemUserPasswordApi(data) {
  const response = await systemUserAxios({
    method: "post",
    url: `/${data._id}/password/reset`,
    data,
  });
  return response.data;
}

export {
  systemUserAxios,
  getSystemUserMeApi,
  searchSystemUsersApi,
  updateSystemUserApi,
  resetSystemUserPasswordApi,
};
