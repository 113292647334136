import { axios } from ".";

const userAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/users`,
});

async function searchUsersApi(searchInput) {
  const response = await userAxios({
    method: "get",
    url: `?name=${searchInput}`,
  });
  return response.data;
}

async function resetUserPasswordApi(data) {
  const response = await userAxios({
    method: "post",
    url: `/${data.clientGuid}/password/reset`,
    data,
  });
  return response.data;
}

export { userAxios, searchUsersApi, resetUserPasswordApi };
