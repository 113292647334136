import { useStoreActions, useStoreState } from "easy-peasy";
import Document from "../presentational/Document";
import Page from "../containers/Page";
import { useEffect } from "react";
import Form from "../functional/Form";
import { useNavigate } from "react-router-dom";
import { links } from "../../utilities";

function usePage() {
  const { chosenSystemUser, systemUserMe } = useStoreState(
    (state) => state.systemUser
  );

  const { setChosenSystemUserAction } = useStoreActions((actions) => actions);

  const navigate = useNavigate();

  useEffect(() => {
    if (systemUserMe.kioskOwner) {
      navigate(links.USERS);
    } else {
      systemUserMe._id && setChosenSystemUserAction(systemUserMe);
    }
  }, [navigate, setChosenSystemUserAction, systemUserMe]);

  return chosenSystemUser;
}

function ProfilePage() {
  const user = usePage();
  return (
    <Page.Protected>
      <Document>{user._id && <Form.Profile />}</Document>
    </Page.Protected>
  );
}

export default ProfilePage;
