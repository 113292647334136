import { Form } from "react-bootstrap";
import tw, { styled } from "twin.macro";

const SForm = styled(Form)`
  max-width: 400px;
  ${tw`w-full`}
`;

const SField = styled(Form.Control)`
  height: 65px;
  font-size: 1.5rem;
`;

function SearchField({
  onChange,
  value,
  label,
  placeholder,
  children,
  ...props
}) {
  return (
    <SForm {...props}>
      <SField
        type="search"
        onChange={onChange}
        value={value}
        label={label}
        placeholder={placeholder}
      />
    </SForm>
  );
}

export default SearchField;
