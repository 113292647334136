import { Col, Form, Row } from "react-bootstrap";
import Button from "../../presentational/Button";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { links } from "../../../utilities";

function useForm() {
  const { chosenSystemUser } = useStoreState((state) => state.systemUser);

  const { setLoadingAction, updateSystemUserThunk } = useStoreActions(
    (actions) => actions
  );

  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (chosenSystemUser._id) {
      setName(chosenSystemUser.name);
      setRole(chosenSystemUser.role);
      setEmail(chosenSystemUser.email);
    }
  }, [
    chosenSystemUser._id,
    chosenSystemUser.email,
    chosenSystemUser.name,
    chosenSystemUser.role,
  ]);

  return {
    chosenSystemUser,
    name,
    onChangeName: (evt) => setName(evt.target.value),
    role,
    onChangeRole: (evt) => setRole(evt.target.value),
    email,
    onChangeEmail: (evt) => setEmail(evt.target.value),
    onNavigateToPasswordReset: () =>
      navigate(
        `${links.PASSWORD_RESET_SYSTEM_USER}?id=${chosenSystemUser._id}`
      ),
    onUpdateUser: async () => {
      setLoadingAction(true);
      await updateSystemUserThunk({
        _id: chosenSystemUser._id,
        name,
        email,
        role,
      });
      setLoadingAction(false);
    },
  };
}

function ProfileForm() {
  const {
    name,
    onChangeName,
    email,
    onChangeEmail,
    role,
    onChangeRole,
    onNavigateToPasswordReset,
    onUpdateUser,
  } = useForm();

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control value={name} onChange={onChangeName} />
          </Form.Group>
        </Col>

        <Col xs={12} md={6}>
          <Form.Group>
            <Form.Label>Role</Form.Label>
            <Form.Control value={role} onChange={onChangeRole} />
          </Form.Group>
        </Col>

        <Col xs={12}>
          <Form.Group>
            <br />
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={email} onChange={onChangeEmail} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <br />
          <Button onClick={onNavigateToPasswordReset}>Reset Password</Button>
        </Col>
        <Col xs={12} md={6}>
          <br />
          <Button onClick={onUpdateUser}>Update User</Button>
        </Col>
      </Row>
    </>
  );
}

export default ProfileForm;
