import { thunk } from "easy-peasy";
import {
  getSystemUserMeApi,
  resetSystemUserPasswordApi,
  searchSystemUsersApi,
  updateSystemUserApi,
} from "../../api/system-user";

const thunks = {
  loadSystemUserMeThunk: thunk(async (actions) => {
    try {
      actions.setSystemUserMeAction(await getSystemUserMeApi());
    } catch (e) {
      actions.setUserSearchResultsAction([]);
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }
  }),
  searchSystemUsersThunk: thunk(async (actions, searchInput) => {
    try {
      actions.setSystemUserSearchResultsAction(
        await searchSystemUsersApi(searchInput)
      );
      actions.resetChosenSystemUserAction();
    } catch (e) {
      actions.setUserSearchResultsAction([]);
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }
  }),
  updateSystemUserThunk: thunk(async (actions, updatedUser) => {
    try {
      const user = await updateSystemUserApi(updatedUser);
      updatedUser._id === user._id && actions.setSystemUserMeAction(user);
      actions.setChosenSystemUserAction(user);
    } catch (e) {
      actions.setUserSearchResultsAction([]);
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }
  }),
  resetSystemUserPasswordThunk: thunk(async (actions, passwordInput) => {
    try {
      await resetSystemUserPasswordApi(passwordInput);
      actions.setAlertThunk({
        type: "success",
        message: "Password has been reset successfully.",
      });
      return true;
    } catch (e) {
      actions.setAlertThunk({
        type: "danger",
        message: e.response.data,
      });
    }
  }),
};

export default thunks;
