import { thunk } from "easy-peasy";
import { loginApi, logoutApi } from "../../api/auth";
import { removeAuthToken, setAuthToken } from "../../utilities/api";

const thunks = {
  loginThunk: thunk(async (actions, loginInput) => {
    try {
      setAuthToken(await loginApi(loginInput));
      await actions.loadSystemUserMeThunk();
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }
  }),
  logoutThunk: thunk(async (actions) => {
    try {
      await logoutApi();
      actions.resetSystemUserMeAction();
      removeAuthToken();
    } catch (e) {
      actions.setAlertThunk({
        type: "error",
        message: e.response.data,
      });
    }
  }),
};

export default thunks;
